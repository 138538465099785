@import-normalize;
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

* {
  font-family: 'Spoqa Han Sans Neo', SpoqaHanSans, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

*,
*:before,
*:after {
  box-sizing: border-box !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  cursor: grab;
}

body {
  background-color: #7e7677;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    conic-gradient(
      from 180deg at 50% 50%,
      #e1bbff 0deg,
      #bbfbeb 46.87deg,
      #edffba 105deg,
      #f8fcc7 165deg,
      #ffd1c7 230.62deg,
      #ffc1fd 286.87deg,
      #e1bbff 360deg
    );*/

  /*background: url('@my/background.jpg') no-repeat center center;
  background-size: cover;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cursor-canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;

  pointer-events: none;

  width: 100vw;
  height: 100vh;
}
